import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable } from 'rxjs';

import { Team, RegistrationService } from '../../services/registration.service';
import { MarketData, MarketDataService } from '../../services/md.service';


@Component({
  selector: 'leaderboard',
  templateUrl: './leaderboard.template.html',
  styleUrls: ['./leaderboard.css']
})

export class LeaderBoardComponent {

	private teams : Observable<Team[]>;
	private md : Observable<MarketData[]>;

	constructor(private registrationService : RegistrationService,
		        private marketDataService : MarketDataService) {
		        
			this.teams = registrationService.subscribeToLeaderBoard();
			this.md = marketDataService.getFeed();
			
	}
	
}
