import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { HighchartsChartModule } from 'highcharts-angular';

import { AppComponent } from './components/app/app.component';

import { ExchangeHomeComponent } from './components/exchange_home/exchangehome.component';
import { RegistrationComponent }  from './components/registration/registration.component';
import { LeaderBoardComponent } from './components/leaderboard/leaderboard.component';

import { OrderBookService } from './services/orderbook.service';
import { MarketDataService } from './services/md.service';
import { RegistrationService } from './services/registration.service';

const appRoutes : Routes = [

	{ path: 'chart/:symbol',	component: ExchangeHomeComponent },
	{ path: 'register',		    component: RegistrationComponent },
	{ path: 'leaderboard',      component: LeaderBoardComponent },
	{ path: '',			        component: ExchangeHomeComponent, pathMatch: 'full'},
	{ path: '**', 			    component: ExchangeHomeComponent }

];

@NgModule({
  declarations: [
  	AppComponent,
	ExchangeHomeComponent,
	RegistrationComponent,
	LeaderBoardComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HighchartsChartModule,
    FormsModule,
    RouterModule.forRoot( appRoutes )
  ],
  providers: [OrderBookService, MarketDataService, RegistrationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
