import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';

/**
 * Represents the content of a market data entry, for a specific product.
 *
 * @author Michael Clarke
 * @version 1.0
 */
export class MarketData {

	public TICKER : String;
	public ASK_PRICE : number;
	public BID_PRICE : number;
	public MAX_SHIFT_PRICE : number;
	public SELL_LIMIT : number;
	public LAST_TRADED_PRICE : number;

}

/**
 * This service provides access to market data from the exchange.  It should be noted
 * that whilst the exchange supports push notifications, with callback urls, this
 * service does not, and as such it polls once a second for information from the
 * exchange.
 *
 * That said, this service does allow for the management of market data services on
 * the exchange - for example, the ability to register new subscribers to the push
 * market data feed, as well as listing and removing existing subscribers.
 *
 * @author Michael Clarke
 * @version 1.0
 */
@Injectable()
export class MarketDataService {

	private marketData : MarketData[];
	private subscribers : String[];
	
	private count : number = 0;

 	/**
 	 * Creates a new MarketData service. Called by Angular.
 	 * 
 	 * Essentially creates a poller, that once a second checks which things it should
 	 * update, and requests that data from the exchange.  For anything updated, a
 	 * local copy is stored so it can be pushed out to any internal subscribers.
 	 *
 	 * The update values are:
 	 *
 	 *	- Market Data Price Updates - 1 second
 	 *	- Market Data Subscriber List - 5 seconds
 	 */
	constructor(private http: HttpClient) { 
		timer(0, 1000).subscribe( (tick)=> {
		
			this.http.get<MarketData[]>('/md')
			.subscribe( (data)=> {
				this.marketData = data;
				console.log(data);
			});
			
			if (--this.count < 0) {
				this.http.get<String[]>('/md/subscription')
				.subscribe( (data)=> {
					this.subscribers = data;
					console.log(data);
				});
				this.count = 5;
			}

		});
	}

	/**
	 * Register as a subscriber within the UI for market data updates.
	 *
	 * The observable updates once a second with the latest market data information
	 * available. 
	 */
	getFeed() : Observable<MarketData[]> {
		return Observable.create( (observer) => {
			timer(0, 1000).subscribe( (tick) => { observer.next(this.marketData) });
		});
	}
	
	/**
	 * Register as a subscriber within the UI for exchange side market data 
	 * subscriber list updates.
	 *
	 * The observable updates once every five (5) seconds with the latest list of
	 * exchange side market data subscribers (essentially a list of call-back URLs). 
	 */
	getSubscriberFeed() : String[] {
		return Observable.create( (observer) => {
			timer(0, 5000).subscribe( (tick) => { observer.next(this.subscribers) });
		});
	}


}
