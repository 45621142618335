import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, timer } from 'rxjs';

import * as Highcharts from 'highcharts';

import { MarketDataService, MarketData } from '../../services/md.service';
import { Order, OrderBookService } from '../../services/orderbook.service';

@Component({
  selector: 'exchange-home',
  templateUrl: './exchangehome.template.html',
  styleUrls: ['./exchangehome.css']
})

export class ExchangeHomeComponent {

	Highcharts: typeof Highcharts = Highcharts;
	private mdUpdates : Observable<MarketData[]>;
	private buyOrders : Order[];
	private sellOrders : Order[];
	private chartData : number[] = [];
	private updateFlag : boolean = false;
	private marketSelected : boolean = false;
	private selectedMarket : String;
	
	private updateTimer : any;

	private chartOptions : Highcharts.Options = {
		series: [],
		title: {text: ''},
		xAxis: {
			labels: { format: '{value}' },
			title: { text: 'Date / Time' }
		}
	};

	constructor( private marketDataService : MarketDataService,
		     private orderBookService : OrderBookService,
		     private route : ActivatedRoute ) { 
		     
		/* Setup the markets!
		 * Everyone can buy up to 10,000 shares, but they can only offload the shares 
		 * at 5,000 a time. Max-shift prices are approx. 10% of the real stock price
		 * of the shares at 2:30pm on 26th Sept. 2019 - with a couple of curve-balls. 
		 */
		orderBookService.createOrderBook("IBM",   10.00,  10000,  5000);
		orderBookService.createOrderBook("AAPL",  15.00,  10000,  5000);
		orderBookService.createOrderBook("ORCL",  5.00,   10000,  5000);
		orderBookService.createOrderBook("TSLA",    3.00,   10000,  5000);
		orderBookService.createOrderBook("GOOGL", 150.00, 10000,  5000);
		orderBookService.createOrderBook("MSFT",  40.00,  10000,  5000);
		orderBookService.createOrderBook("AMZN",  200.00, 10000,  5000);
		orderBookService.createOrderBook("NFLX",  20.00,  10000,  5000);
				
		/* Subscribe to a market data feed. Use it to update the graph with the
		 * latest market price information for this symbol.
		 */
		this.mdUpdates = this.marketDataService.getFeed();
		     
	} 
	
	ngOnDestroy() {
		if (this.updateTimer) {
			this.updateTimer.unsubscribe();
		}
	}

	ngOnInit() {
	
		this.route.paramMap.subscribe(
			
			(params) => {

				if (this.updateTimer) {
					this.updateTimer.unsubscribe();
				}
				
				let symbol = params.get("symbol");
				if (symbol === null) return;
				
				this.selectedMarket = symbol;
				this.marketSelected = true;
				
				this.updateTimer = timer(0, 1000).subscribe( (tick)=> {
				
					this.orderBookService.getOrdersForSymbol(symbol)
					.subscribe( (update) => {
					
						let executions = [];
						
						update.forEach( (order) => {
							if (order.side === "BUY") {
								order.executions.forEach( (execution) => {
									executions.push( {name: execution.timestamp, y: execution.price} )
								});
							}
						});
							
						executions.sort( (a, b) : number => {
							if (a.name < b.name) return -1;
							if (a.name > b.name) return 1;
							return 0;
						});
						
						this.chartOptions.series = [ 
						{ 
							data: executions, 
							type: 'area',
        					color: Highcharts.getOptions().colors[2],
       						fillOpacity: 0.5,
        				    name: symbol,
        				} ]
						this.updateFlag = true;
					
					});
				
					this.orderBookService.getBuyOrdersForSymbol(symbol)
					.subscribe( (update) => {
					
						this.buyOrders = update;
					});
					
					this.orderBookService.getSellOrdersForSymbol(symbol)
					.subscribe ( (update) => {
						this.sellOrders = update;
					});
				
				});

			});

	}
	
 
}
