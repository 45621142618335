import { Component } from '@angular/core';

@Component({
  selector: 'app',
  templateUrl: './app.template.html',
  styleUrls: ['./app.css']
})

export class AppComponent {
}
