import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable } from 'rxjs';

import { RegistrationService } from '../../services/registration.service';


@Component({
  selector: 'registration',
  templateUrl: './registration.template.html',
  styleUrls: ['./registration.css']
})

export class RegistrationComponent {

	apiKey : String;
	keyPresent : Boolean = false;
	
	constructor(private registrationService : RegistrationService) {
	
	}
	
	registerTeam(form : NgForm) {
	
		this.registrationService.register(form.value.teamName)
		.subscribe( (result) => {
			this.apiKey = result;
			this.keyPresent = true;	
		})
	
	}
 
 	
 

}
