import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

/**
 * Used to limit queries to represent the state of an order.
 *
 * @author Michael Clarke
 * @version 1.0
 */
export enum OrderStatus {
	OPEN, BUY, SELL, CLOSED, CANCELLED
}

export class Execution {
	public timestamp : String;
	public price : number;
	public quantity : number;
}

export class Order {
	public product : String;
	public quantity : number;
	public price : number;
	public side : String;
	public executions : Execution[];
	public getCumulatitiveQuantity : number;
}

/**
 * This service allows for interactions with the order book remote services.  It allows for the creation
 * and querying or oder books. 
 *
 * @author Michael Clarke
 * @version 1.0
 */
@Injectable()
export class OrderBookService {

	/**
	 * Creates a new Order Book service object, via the framework.
	 *
	 * @param http We require a HTTP client to talk with the backend service.
	 */
	constructor(private http: HttpClient) { 
		console.log("In constructor for OrderBookService.");
		
	
	}
	
	/**
	 * Creates a new order book for a specified product, with the specified limits. 
	 *
	 * Note there is always an implied limit of a minimum of 1 products to be sold/purchased.  The remote service
	 * rejects anything that is < 1 - i.e. a sale or quantity of 0 or less items/price. 
	 *
	 * @param p_ticker The product/ticker the order book is to be created for (e.g. IBM).
	 * @param p_maxShift The maximum amount the price is allowed to increase/decrease compared to the current product BID/ASK price.
	 * @param p_buyLimit The maximum number of stock that can be purchased in any one order.
	 * @param p_sellLimit The maximum number of stock that can be sold in any one order.
	 *
	 */
	createOrderBook(p_ticker : String, p_maxShift : Number, p_buyLimit : Number, p_sellLimit : Number) {

		let orderBook = {
		
			ticker: p_ticker,
			maxShift: p_maxShift,
			buyLimit: p_buyLimit,
			sellLimit: p_sellLimit
			
		};

		console.log("Creating order book: ", orderBook);
	
		this.http.post("/orderbook", orderBook).subscribe();
	
	}
	
	getOrdersForSymbol(symbol : String) : Observable<Order[]> {
		return this.http.get<Order[]>("/orderbook/" + symbol);
	}
	
	getSellOrdersForSymbol(symbol: String) : Observable<Order[]> {
		return this.http.get<Order[]>("/orderbook/" + symbol + "/sell");
	}
	
	getBuyOrdersForSymbol(symbol : String) : Observable<Order[]> {
		return this.http.get<Order[]>("/orderbook/" + symbol + "/buy");
	}
	
}
