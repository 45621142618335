import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer } from 'rxjs';

export class Team {
	name : String;
	profit : number;
	position : number;
	cashBalance : number;
	positions : [];
}

/**
 * This service provides the functionality for registering to the exchange.
 *
 * @author Michael Clarke
 * @version 1.0
 */
@Injectable()
export class RegistrationService {

	private teams : Team[];
	private subscribers : String[];
	
	private count : number = 0;

	constructor(private http: HttpClient) { 
	
			timer(0, 5000).subscribe( (tick)=> {
			
				this.http.get<Team[]>('/pnl').subscribe( (data)=> {
				
					data.sort( (a, b) => {
						return b.profit - a.profit;
					});
					
					let position : number = 0;
					
					data.forEach( (team) => {
						team.position = ++position;
					});

					this.teams = data;
					
				});
				
			});
	
	}

	register(teamName : String) : Observable<String> {
		return this.http.post<String>("/register", teamName);
	}
	
	subscribeToLeaderBoard() : Observable<Team[]> {
	
		return Observable.create( (observer) => {
			timer(0, 1000).subscribe( (tick) => { observer.next(this.teams) });
		});
	
	}

}
